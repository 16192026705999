<template>
  <div class="season">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Season"
    />
    <div v-if="season">
      <v-card>
        <v-img
          :src="(season.image) ? season.image : ''"
          height="200px"
          class="px-3"
        >
          <v-row class="mb-4">
            <v-col>
              <div class="headline">
                {{ season.name }}
              </div>
              <div class="title">
                {{ season.num_quests }} Quests
              </div>
            </v-col>
          </v-row>
          <v-row class="align-end pt-5">
            <v-col>
              <div class="title">
                {{ season.num_profiles }}
              </div>
              <div>
                Participants
              </div>
            </v-col>
            <v-col align="right">
              <div class="title">
                {{ season.points_earned }}
              </div>
              <div>
                Points Earned
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>

      <v-row>
        <v-col>
          <Share
            :title="season.name"
            class="mr-4"
          />
          <Like
            :object-id="season.id"
            app="quest"
            model="season"
          />
        </v-col>
      </v-row>

      <div class="quest-meta mb-6">
        <v-divider />
        <v-row>
          <v-col>
            Dates
          </v-col>
          <v-col class="text-right">
            {{ season.start|formatDate }} &#8211; {{ season.stop|formatDate }}
          </v-col>
        </v-row>
        <v-divider />
      </div>

      <div class="section">
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="season.dispatch" />
        <!--eslint-enable-->
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab>
          Leaderboard
        </v-tab>
        <v-tab>
          Quests
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item>
          <Loading
            v-if="loading"
            :loading="loading"
            message="Loading Leaderboard"
          />
          <LeaderList
            :entry-list="season.profiles"
            :season="season"
          />
        </v-tab-item>
        <v-tab-item>
          <quest-list :season-id="season.id" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import LeaderList from '@/components/LeaderList.vue'
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import QuestList from '@/components/QuestList.vue'
import Share from '@/components/Share.vue'
import { mapState } from 'vuex'

export default {
  components: {
    LeaderList,
    Like,
    Loading,
    QuestList,
    Share,
  },
  data: function() {
    return {
      alerts: [],
      season: null,
      tab: null,
    }
  },
  computed: {
    ...mapState({
      loading: state => state.seasons.loading,
    }),
  },
  created: function () {
    this.getSeason()
  },
  methods: {
    getSeason() {
      return this.$store.dispatch('seasons/getAll')
        .finally(() => {
          this.season = this.$store.getters['seasons/getBySlug'](this.$route.params.slug) || null
          if (!this.$online && !this.season) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to view this season.',
            })
          } else if (!this.season) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to load season.',
            })
          }
        })
    },
  },
}
</script>
